




































































































import { Component, Watch } from 'vue-property-decorator'
import { DashmixBoxTabItem, DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../../extensions'

import { FormFieldset, SlideTabLabel } from '../../../atoms'
import { Iterator } from '../../../molecules'
import { REFRESH_MDE_EVENT } from '../../../molecules/MarkdownEditor'

import { AbstractModuleForm, getDefaultModuleAlignment } from '../../_abstract'
import { TileModuleForm } from '../../Tile/form'
import { TileModuleContent } from '../../Tile'

import { carouselContentFactory } from '../Corousel.factory'
import { CarouselBulletColor, CarouselModule, CarouselModuleContent } from '../Carousel.contracts'

import { getTileModuleContentBase } from './Carousel.form.config'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<CarouselModuleForm>({
  name: 'CarouselModuleForm',
  components: { FormFieldset, Iterator, TileModuleForm }

  // created (): void {
  //   this.populateWithBreakpoints()
  // }
})
export class CarouselModuleForm extends AbstractModuleForm<CarouselModule> {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  public icon = DashmixIconName

  /**
   * Initial active tab
   */
  public activeTab: string = '0'

  /**
   * Show/Hide breakpoints form
   */
  public overrideBreakpoints: boolean = false

  /**
   * Tile's alignment in container
   */
  public tileAlignment = getDefaultModuleAlignment()

  /**
   * @inheritDoc
   */
  protected initialContent: CarouselModuleContent<TileModuleContent> = carouselContentFactory()

  // TODO: Below code has been commented temporarily
  // As for now below implementation has been commented out for the reason that
  // response that we have from API does not satisfy the way `swiperOptions.breakpoints` was implemented.
  // Client expects from API `swiperOptions.breakpoints` to be object whereas API returns array.
  //
  // /**
  //  * Lists all possible carousel breakpoints options
  //  */
  // public get carouselBreakpointOptions (): { label: string; value: number }[] {
  //   return [
  //     ...Object.entries(this.modulesBreakpointsRegistry).map(([label, value]) => {
  //       return {
  //         label,
  //         value
  //       }
  //     })
  //   ]
  // }

  /**
   * Determines all possible color options for bullets.
   */
  public get bulletColorOptions (): DashmixSelectItem[] {
    return Object.entries(CarouselBulletColor).map(([label, value]) => {
      return {
        label: String(this.$t(`formHelpers.theme.options.${label}`)),
        value: value
      }
    })
  }

  /**
   * Checks whether `_content.swiperOptions` is present
   */
  public get hasSwiperOptions (): boolean {
    return typeof this._content.swiperOptions !== 'undefined'
  }

  /**
   * Slides as tabs
   */
  public get slideTabs (): DashmixBoxTabItem[] {
    return [
      ...this._content.slides.map((slide, index) => {
        return {
          component: TileModuleForm,
          props: {
            relatedService: this.relatedService,
            pickRelated: this.pickRelated,
            content: this._content.slides[index]
          },
          tab: {
            id: `${index}`,
            disabled: false,
            label: SlideTabLabel as unknown as string, // FIXME!
            props: {
              tabIndex: index,
              label: `${this.$t('forms.Carousel.tab.slide')} ${index + 1}`,
              onSlideRemove: this.removeSlide
            }
          }
        }
      })
    ]
  }

  /**
   * Adds new slide to `content.slide`
   */
  public addNewSlide (): void {
    const baseContent = getTileModuleContentBase()

    if (this._content.slides.length >= 1) {
      const firstSlide = this._content.slides[0]
      const { border, large, rounded, shadow, transparent } = firstSlide
      const firstSlideSettings = { border, large, rounded, shadow, transparent }

      this._content.slides.push({
        ...baseContent,
        ...firstSlideSettings
      })
    } else {
      this._content.slides.push(baseContent)
    }
  }

  // /**
  //  * Adds default breakpoints to carousel
  //  */
  // public populateWithBreakpoints (): void {
  //   if (this.hasSwiperOptions) {
  //     return
  //   }
  //
  //   this._content = {
  //     ...this._content,
  //     swiperOptions: {
  //       breakpoints: {
  //         [carouselModuleBreakpointScreenWidthRegistry.desktop]: {
  //           slidesPerView: 3,
  //           slidesPerGroup: 3
  //         },
  //         [carouselModuleBreakpointScreenWidthRegistry.phone]: {
  //           slidesPerView: 1,
  //           slidesPerGroup: 1
  //         },
  //         [carouselModuleBreakpointScreenWidthRegistry.tablet]: {
  //           slidesPerView: 2,
  //           slidesPerGroup: 2
  //         }
  //       }
  //     }
  //   }
  // }

  /**
   * Watches for changes of active tab
   */
  @Watch('activeTab')
  private onActiveTabChange (): void {
    this.refreshTilesMDE()
  }

  /**
   * Replaces current slide with updated item
   */
  public onContentUpdate (item: TileModuleContent, index: string): void {
    const contentSlideCopy = [...this._content.slides]

    contentSlideCopy[Number(index)] = item

    if (('rounded' in item) || ('border' in item) || ('transparent' in item) || ('shadow' in item)) {
      // Propagate values for tile settings through all slides
      this._content.slides = contentSlideCopy.map((slide: TileModuleContent) => ({
        ...slide,
        border: item.border,
        shadow: item.shadow,
        rounded: item.rounded,
        transparent: item.transparent
      }))
    } else {
      this._content.slides = contentSlideCopy
    }
  }

  /**
   * Refreshes the SimpleMDE editors inside the <TileModuleForm> components.
   */
  private refreshTilesMDE (): void {
    this.eventBus.emit(REFRESH_MDE_EVENT)
  }

  /**
   * Removes slide from `_content.slides` by its index
   */
  private removeSlide (index: number): void {
    const slidesCopy = [...this._content.slides]

    slidesCopy.splice(index, 1)

    this._content.slides = slidesCopy

    if (this.activeTab !== '0') {
      this.activeTab = (index - 1).toString()
    }
  }
}

export default CarouselModuleForm

