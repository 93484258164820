
























































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'

import { Heading, HorizontalAlignment } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { IconSelect } from '../../../atoms/IconSelect'
import { MarkdownEditor } from '../../../molecules/MarkdownEditor'
import {
  HeadingForm,
  LinkForm,
  MapSelector,
  TileBehavior,
  TileIconColor,
  TileIconPlacement
} from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { tileContentFactory } from '../Tile.factory'
import { TileModule, TileModuleContent } from '../Tile.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
@Component<TileModuleForm>({
  name: 'TileModuleForm',
  components: { FormFieldset, HeadingForm, LinkForm, MarkdownEditor, MapSelector, IconSelect },
  mounted () {
    if (typeof this._content.extendedBody !== 'undefined') {
      this.extendedBody = true
    }

    if (typeof this._content.dataCircle !== 'undefined') {
      this.dataCircle = this._content.dataCircle
    }
  }
})
export class TileModuleForm extends AbstractModuleForm<TileModule> {
  /**
   * This prop is responsible for `TilesSettings`.
   *
   * IMPORTANT!: Use this prop from parent component if you are sure that settings of Tile
   * whether are not required or defined somewhere else.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disableSettings!: boolean

  /**
   * @see TileModuleContent.dataCircle
   */
  public dataCircle: TileModuleContent['dataCircle'] = {
    content: '',
    fillPercentage: undefined
  }

  public get _dataCircle (): boolean {
    return !!this._content.dataCircle
  }

  public set _dataCircle (value: boolean) {
    if (value) {
      this._content = { ...this._content, dataCircle: this.dataCircle }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dataCircle, ...rest } = this._content
      this._content = { ...rest }
    }
  }

  public extendedBody = false
  public initialContent: TileModuleContent = tileContentFactory()
  public readonly iconColorOptions = TileIconColor
  public readonly iconPlacementOptions = TileIconPlacement
  public readonly icons = DashmixIconName

  /**
   * Horizontal alignment options for heading / content
   *
   * @see HorizontalAlignment
   */
  public get horizontalAlignmentOptions (): DashmixSelectItem[] {
    return [...Object.entries(HorizontalAlignment).map(([label, value]) => {
      return {
        label: String(this.$t(`formHelpers.alignment.options.${label}`)),
        value
      }
    })]
  }

  public updateExtendedBody (value: boolean): void {
    this.extendedBody = value

    if (value) {
      this._content.extendedBody = ''
    } else {
      delete this._content.extendedBody
    }
  }

  public onHeadingUpdate (heading: Heading): void {
    this._content = {
      ...this._content,
      heading: heading
    }
  }

  /**
   * All behavior options fot Tile component
   */
  public get behaviorOptions (): DashmixSelectItem[] {
    return Object.entries(TileBehavior).map(([behavior, value]) => ({
      label: String(this.$t(`forms.tile.behavior.${behavior}`)),
      value
    }))
  }

  /**
   * Override `_content`
   *
   * @param value - unknown [value of updating key might differ]
   * @param key - string [key in object for which value should be assigned]
   *
   * FIXME: Not sure what type of value should be used `any` or `unknown`
   * For now `unknown` left
   */
  public updateByKey (value: unknown, key: string): void {
    const contentCopy = { ...this._content }

    contentCopy[key] = value

    this._content = contentCopy
  }

  /**
   * Watches the `dataCircle` property and reacts on its changes.
   */
  @Watch('dataCircle', { deep: true })
  private onDataCircleChange (dataCircle: TileModuleForm['dataCircle']): void {
    this._content = { ...this._content, dataCircle }
  }
}

export default TileModuleForm
