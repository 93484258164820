// Copyright © 2022 Move Closer

import { TileBehavior } from '../../../molecules'

import { TileModuleContent } from '../../Tile'

/**
 * @returns - Most basic representation of the `TileModuleContent` interface.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getTileModuleContentBase = (): TileModuleContent => ({
  behavior: TileBehavior.None,
  body: '',
  heading: {
    level: 3,
    text: ''
  },
  large: false
})
